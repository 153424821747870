import {ChangeDetectionStrategy, Component} from '@angular/core'

@Component({
  selector: 'cft-dialog-footer',
  template: '<ng-content/>',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: `
  :host {
    @apply sticky bg-white bottom-0 py-sm px-md border-t border-grey-200 flex justify-between gap-sm flex-col sm:flex-row;
  }
  `,
})
export class DialogFooterComponent {}
